import { Flex, IconButton } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { MdClose } from 'react-icons/md';

export type DisplayFileWrapperProps = {
  showRemoveIcon: boolean;
  children: ReactNode;
  onClick: () => void;
};

const DisplayFileWrapper: FC<DisplayFileWrapperProps> = (props: DisplayFileWrapperProps) => {
  const { showRemoveIcon, children, onClick } = props;

  return (
    <Flex borderRadius='4px' overflow='hidden' position='relative' justifyContent='center'>
      {children}
      {showRemoveIcon && (
        <IconButton
          size='xs'
          onClick={onClick}
          icon={<MdClose />}
          aria-label='remove icon'
          position='absolute'
          top='3px'
          right='5px'
          fontSize='xl'
          boxShadow='1px 2px 5px 1px rgba(0, 0, 0, 0.3)'
          isRound
        />
      )}
    </Flex>
  );
};

export default DisplayFileWrapper;
